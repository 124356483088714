<template>
  <div class="third-party">
    <StepNavbar ref="stepNavbar"></StepNavbar>
    <div class="content">
      <p>
        <el-button icon="el-icon-arrow-left" type="text" @click="toLast">上一步</el-button>
      </p>
      <p>
        <el-form ref="form" :model="form" label-width="260px">


          <el-form-item label="银联卡开户银行及支行（全称）">
            <el-input v-model="form.bankName"></el-input>
          </el-form-item>
          <el-form-item label="银联卡账号">
            <el-input v-model="form.bankCard"></el-input>
          </el-form-item>
          <el-form-item label="支付宝账号">
            <el-input v-model="form.alipay"></el-input>
          </el-form-item>
          <el-form-item label="微信号">
            <el-input v-model="form.wechat"></el-input>
          </el-form-item>
          <el-form-item label="电子邮箱">
            <el-input v-model="form.email"></el-input>
          </el-form-item>
          <el-form-item label="QQ号">
            <el-input v-model="form.qq"></el-input>
          </el-form-item>
          <el-form-item class="text-center">
			  <el-button class="btn-next" style="width: 80%;" @click="toNext2">您已填写完毕，点击此处提交，申请注册审核</el-button>
           <!-- <el-button class="btn-next" style="width: 80%;" @click="toNext">您已填写完毕，点击此处提交，申请注册审核</el-button> -->
          </el-form-item>
        </el-form>
      </p>
    </div>
  </div>
</template>

<script>
  import StepNavbar from "./components/StepNavbar";
  import {getSessionUser} from "../../utils/Storage";

  export default {
    name: 'ThirdParty',
    components: {StepNavbar},
    data() {
      return {
        form: {}
      }
    },

    mounted() {
      this.$refs['stepNavbar'].setStepNode(4)
    },

    methods: {

      /**
       * 执行上一步
       */
      toLast() {
        this.$emit("toLast", 3);
      },


      /**
       * 执行下一步的操作
       */
      toNext() {
        this.form.accountId = getSessionUser().id;
        this.$emit("toNext", this.form)
      },
	  toNext2(){
        this.form.accountId = getSessionUser().id;
        this.$emit("toNext", this.form)
	    this.$message.success('操作成功！');
		// this.$router.replace('/home')
	  }
    }
  }
</script>

<style scoped>
  .wrap {
    width: 1200px;
    margin: 20px auto;
  }

  .content {
    background: #FFFFFF;
    padding: 20px 90px 50px;
    margin-bottom: 50px;
  }

  .title {
    text-align: center;
    /*display: inline-block;*/
    /*transform: translateX(-12%);*/
    line-height: 50px;
    color: #ffffff;
    font-size: 13px;
  }

  .description {
    margin-bottom: 40px;
  }

  .tips {
    color: #DA575D;
    font-size: 13px;
  }

  p {
    margin-bottom: 0px;
  }

  .el-checkbox.is-bordered {
    padding-left: 0px !important;
    border: unset;
  }

  .el-button--text {
    color: #3BD1DC !important;
  }

  .el-row.step {
    height: 50px;
    margin-bottom: 10px;
  }

  .step .el-col {
    background-image: url("../../assets/images/bg-step.png");
    height: 100%;
    background-size: 100% 100%;
  }

  .step .el-col:first-child {
    background-image: url("../../assets/images/bg-step01.png");
  }

  .step .bg-white {
    background-image: url("../../assets/images/bg-step02.png");

  }

  .step .bg-white .title {
    color: #000;
  }

  .el-form {
    padding: 0px 250px 0px 100px;
  }

  .el-form .el-radio-group {
    width: 100%;
  }

  .el-form .btn-next {
    width: 50%;
  }

  .el-form .captcha .text-input {
    padding-left: 0px !important;
  }

  .el-form .captcha .text-captcha {
    height: 35px;
  }

  .el-form .captcha .text-captcha img {
    margin-top: 2px;
    cursor: pointer;
  }

  .el-form .el-radio-group {
    text-align: center;
  }

  .el-form .addr .el-col {
    padding: 0px 2px 0px 0px;
  }

  .el-form .addr .el-col:last-child {
    padding: 0px;
  }

  .el-row.idcard .el-col .el-image {
    width: 100%;
    min-height: 80px;
    border: 1px solid #cccccc;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }

  .el-row.idcard .el-col {
    padding: 0px 5px;
  }

  .el-row.idcard .el-col .desc {
    font-size: 12px;
    color: #cccccc;
    text-align: center;
    margin-top: -10px;
  }

  .idcard-tips {
    font-size: 12px;
    color: #F25A60;
  }
</style>
