<template>
  <div class="protocol">
    <el-row class="step">
      <el-col :span="4" class="text-center">
        <span class="title">1. 选择注册者身份</span>
      </el-col>
      <el-col :span="4" class="text-center">
        <span class="title">2. 设置登录账号</span>
      </el-col>
      <el-col :span="4" class="text-center">
        <span class="title">3. 身份信息登记</span>
      </el-col>
      <el-col :span="4" class="text-center">
        <span class="title">4. 专业学术证件登记</span>
      </el-col>
      <el-col :span="4" class="text-center">
        <span class="title">5. 银行收付款账号等登记</span>
      </el-col>
      <el-col :span="4" class="text-center">
        <span class="title">6. 提交审核</span>
      </el-col>
    </el-row>

    <div class="content">
      <el-steps direction="vertical" :active="6">
        <el-step title="选择注册者身份（个人、医生、合作单位或个人)，进入各自的注册流程。">
          <div slot="description" class="description"></div>
        </el-step>
        <el-step title="设置登录账号、密码，登记本人手机号码并验证。">
          <div slot="description" class="description">
            <ol>
              <li>登录账号为6至20位英文大写字母、英文小写字母、阿拉伯数字（必须包含）中任意至少2项的组合；如：lys119；</li>
              <li>登录账号具有唯一性，他人已注册时，则需要改其他的登录账号注册，配合本人设置的密码用于登录，请谨记并保存，注意保密。</li>
              <li>手机号码与验证码可用于快速登录，登录账号 、手机号码均不公开。</li>
              <li>设置的密码用于登录，请谨记并保存好，密码为6至20位英文大写字母、英文小写字母、阿拉伯数字、特殊符号中任意3项的组合；</li>
            </ol>
          </div>
        </el-step>
        <el-step title="身份信息登记">
          <div slot="description" class="description">
            <ol>
              <li>登记本人真实姓名，须与身份证及手机号码登记姓名一致，企业、单位为代理人（一般为法人）姓名、家庭地址等信息根据提示要求填写。</li>
              <li>其他：姓名、性别、民族、国籍、年龄、身高、体重、籍贯，住址等根据提示要求填写。</li>
              <li>医生需上传本人手持身份证照片，（仿照图例，须同时看清上面的文字及数字）。</li>
              <li>合作单位或个体工商户,除上传代理人（一般为法人）手持身份证外，尚需要上传代理人手持委托书及工商营业执照的照片，并认真填写有关代码。</li>
            </ol>
          </div>
        </el-step>
        <el-step title="专业学术证件登记，主要限于医生、医疗相关服务人员">
          <div slot="description" class="description">
            <ol>
              <li>包括毕业院校（一般为医学最高学历，或与从事专业最相关者）及毕业时间、专业、毕业证书、学位证书、医生各类资格证书及编号。</li>
              <li>只登记通过全国统一考试取得的医学各专业主治医师资格证书（中级）或医师资格证书（初级）的证件号码及取得时间（以证件上的时间为准）即可，如无上述两种通过标准考试取得的证书，登记通过评选取得的副主任医师资格证书（副高级）也可（提示用户：评选人为因素较多，咨询时不可作为判断医疗水平的主要标准，应主要参考学历，综合考虑）；乡村医生或医士证书取得时间及编号也需登记，并注明证书名称；</li>
            </ol>
          </div>
        </el-step>
        <el-step title="银行付款账号、联系方式等登记">
          <div slot="description" class="description">
            <ol>
              <li>包括，微信、支付宝、银联卡账号登记（用于咨询服务中的收款付费，含开户银行、支行，带人看病者请务必登记，以免收益无法到账）。</li>
              <li>以病人身份注册者可在注册成功后，有收付款需要时，进一步到个人中心完善信息。</li>
              <li>其他信息：电子邮箱、微信号、QQ号，（最好有，以便及时沟通）。</li>
            </ol>
          </div>
        </el-step>
        <el-step title="提交、等待审核">
          <div slot="description" class="description">
            <ul>
              <li>用户注册信息均不对外公开</li>
            </ul>
          </div>
        </el-step>
      </el-steps>

      <p class="text-center tips">请如实填写信息，否则一切责任自负！（用户所填信息均不公开）</p>
      <p class="text-center" style="margin-top: 10px;">
        <el-button @click="toNext">我知道了，下一步</el-button>
      </p>
      <p class="text-center">
        <el-button type="text" @click="toLogin">已注册，去登录</el-button>
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Protocol',
    methods:{

      /**
       * 执行下一步的操作
       */
      toNext(){
        this.$emit('next')
      },
      /**
       * 打开登录的页面
       */
      toLogin() {
        this.$router.replace('/login')
      },

    }
  }
</script>

<style scoped>
  .wrap {
    width: 1200px;
    margin: 20px auto;
  }

  .content {
    background: #FFFFFF;
    padding: 20px 90px 50px;
    margin-bottom: 50px;
  }

  .title {
    text-align: center;
    /*display: inline-block;*/
    /*transform: translateX(-12%);*/
    line-height: 50px;
    color: #ffffff;
    font-size: 13px;
  }

  .description {
    margin-bottom: 40px;
  }

  .tips {
    color: #DA575D;
    font-size: 13px;
  }

  p {
    margin-bottom: 0px;
  }

  .el-checkbox.is-bordered {
    padding-left: 0px !important;
    border: unset;
  }

  .el-button--text {
    color: #3BD1DC !important;
  }

  .el-row.step {
    height: 50px;
    margin-bottom: 10px;
  }

  .step .el-col {
    background-image: url("../../assets/images/bg-step.png");
    height: 100%;
    background-size: 100% 100%;
  }

  .step .el-col:first-child {
    background-image: url("../../assets/images/bg-step01.png");
  }

  .step .bg-white {
    background-image: url("../../assets/images/bg-step02.png");

  }

  .step .bg-white .title {
    color: #000;
  }

  .el-form {
    padding: 0px 250px;
  }

  .el-form .el-radio-group {
    width: 100%;
  }

  .el-form .btn-next {
    width: 50%;
  }

  .el-form .captcha .text-input {
    padding-left: 0px !important;
  }

  .el-form .captcha .text-captcha {
    height: 35px;
  }

  .el-form .captcha .text-captcha img {
    margin-top: 2px;
    cursor: pointer;
  }

  .el-form .el-radio-group {
    text-align: center;
  }

  .el-form .addr .el-col {
    padding: 0px 2px 0px 0px;
  }

  .el-form .addr .el-col:last-child {
    padding: 0px;
  }

  .el-row.idcard .el-col .el-image {
    width: 100%;
    min-height: 80px;
    border: 1px solid #cccccc;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }

  .el-row.idcard .el-col {
    padding: 0px 5px;
  }

  .el-row.idcard .el-col .desc {
    font-size: 12px;
    color: #cccccc;
    text-align: center;
    margin-top: -10px;
  }

  .idcard-tips {
    font-size: 12px;
    color: #F25A60;
  }
</style>
