<template>
  <div class="edu-agent">
    <StepNavbar ref="stepNavbar"></StepNavbar>
    <div class="content">
      <p>
        <el-button icon="el-icon-arrow-left" type="text" @click="toLast">上一步</el-button>
      </p>
      <p>
        <el-form ref="form" :model="form" label-width="160px" :rules="formRule">
          <el-form-item label="被委托人学历" prop="education">
            <el-input v-model="form.education"></el-input>
          </el-form-item>
          <el-form-item label="姓名" prop="agentName">
            <el-input v-model="form.agentName"></el-input>
          </el-form-item>
          <el-form-item label="与委托人关系" prop="relationship">
            <el-input v-model="form.relationship"></el-input>
          </el-form-item>
          <el-form-item label="单位内职务" prop="job">
            <el-input v-model="form.job"></el-input>
          </el-form-item>
          <el-form-item label="被委托人毕业学校" prop="agentColleges">
            <el-input v-model="form.agentColleges"></el-input>
          </el-form-item>
          <el-form-item label="所学专业" prop="agentMajor">
            <el-input v-model="form.agentMajor"></el-input>
          </el-form-item>
          <el-form-item label="从事职业" prop="agentProfession">
            <el-input v-model="form.agentProfession"></el-input>
          </el-form-item>

          <el-form-item label="我的自白，其他情况" prop="specialty">
            <el-input v-model="form.specialty" type="textarea" :rows="4" maxlength="500" show-word-limit
                      placeholder="代理人专业技能及特长"></el-input>
          </el-form-item>
          <el-form-item label="   " prop="businessSope">
            <el-input v-model="form.businessScope" type="textarea" :rows="4" maxlength="500" show-word-limit
                      placeholder="单位所从事 / 经营的主要内容，如涉密可不填"></el-input>
          </el-form-item>
          <el-form-item class="text-center">
            <el-button class="btn-next" @click="toNext">下一步</el-button>
          </el-form-item>
        </el-form>
      </p>
    </div>
  </div>
</template>

<script>
  import StepNavbar from "./components/StepNavbar";
  import {getSessionUser} from "../../utils/Storage";

  export default {
    name: 'EduAgent',
    components: {
      StepNavbar
    },
    mounted() {
      this.$refs['stepNavbar'].setStepNode(3);
    },
    data() {
      return {
        form: {},
        formRule: {
          education: [
            {required: true, message: "学历不能为空", trigger: "blur"}
          ],
          agentName: [
            {required: true, message: "委托人姓名不能为空", trigger: "blur"}
          ],
          relationship: [
            {required: true, message: "与委托人关系不能为空", trigger: "blur"}
          ],
          job: [
            {required: true, message: "单位内职务不能为空", trigger: "blur"}
          ],
          agentColleges: [
            {required: true, message: "被委托人毕业学校不能为空", trigger: "blur"}
          ],
          agentMajor: [
            {required: true, message: "被委托人所学专业不能为空", trigger: "blur"}
          ],
          agentProfession: [
            {required: true, message: "被委托人从事职业不能为空", trigger: "blur"}
          ]
        }
      }
    },

    methods: {

      /**
       * 执行下一步的操作
       */
      toNext() {
        this.$refs['form'].validate((valid) => {
          if (valid){
            this.form.accountId = getSessionUser().id;
            this.$emit("toNext", this.form);
          }
        })
      },

      /**
       * 执行上一步的操作
       */
      toLast() {
        this.$emit("toLast", 2);
      }
    }
  }
</script>

<style scoped>
  .wrap {
    width: 1200px;
    margin: 20px auto;
  }

  .content {
    background: #FFFFFF;
    padding: 20px 90px 50px;
    margin-bottom: 50px;
  }

  .title {
    text-align: center;
    /*display: inline-block;*/
    /*transform: translateX(-12%);*/
    line-height: 50px;
    color: #ffffff;
    font-size: 13px;
  }

  .description {
    margin-bottom: 40px;
  }

  .tips {
    color: #DA575D;
    font-size: 13px;
  }

  p {
    margin-bottom: 0px;
  }

  .el-checkbox.is-bordered {
    padding-left: 0px !important;
    border: unset;
  }

  .el-button--text {
    color: #3BD1DC !important;
  }

  .el-row.step {
    height: 50px;
    margin-bottom: 10px;
  }

  .step .el-col {
    background-image: url("../../assets/images/bg-step.png");
    height: 100%;
    background-size: 100% 100%;
  }

  .step .el-col:first-child {
    background-image: url("../../assets/images/bg-step01.png");
  }

  .step .bg-white {
    background-image: url("../../assets/images/bg-step02.png");

  }

  .step .bg-white .title {
    color: #000;
  }

  .el-form {
    padding: 0px 200px;
  }

  .el-form .el-radio-group {
    width: 100%;
  }

  .el-form .btn-next {
    width: 50%;
  }

  .el-form .captcha .text-input {
    padding-left: 0px !important;
  }

  .el-form .captcha .text-captcha {
    height: 35px;
  }

  .el-form .captcha .text-captcha img {
    margin-top: 2px;
    cursor: pointer;
  }

  .el-form .el-radio-group {
    text-align: center;
  }

  .el-form .addr .el-col {
    padding: 0px 2px 0px 0px;
  }

  .el-form .addr .el-col:last-child {
    padding: 0px;
  }

  .el-row.idcard .el-col .el-image {
    width: 100%;
    min-height: 80px;
    border: 1px solid #cccccc;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }

  .el-row.idcard .el-col {
    padding: 0px 5px;
  }

  .el-row.idcard .el-col .desc {
    font-size: 12px;
    color: #cccccc;
    text-align: center;
    margin-top: -10px;
  }

  .idcard-tips {
    font-size: 12px;
    color: #F25A60;
  }
</style>
