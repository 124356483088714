<template>
  <div class="edu-patient">
    <StepNavbar ref="stepNavbar"></StepNavbar>
    <div class="content">
      <p>
        <el-button icon="el-icon-arrow-left" type="text" @click="toLast">上一步</el-button>
      </p>
      <p>
        <el-form ref="form" :model="form" label-width="120px">
          <el-form-item label="学历">
            <el-input v-model="form.education"></el-input>
          </el-form-item>
          <el-form-item label="毕业学校">
            <el-input v-model="form.colleges"></el-input>
          </el-form-item>
          <el-form-item label="从事职业">
            <el-input v-model="form.profession"></el-input>
          </el-form-item>
          <el-form-item label="其他">
            <el-input v-model="form.memo" type="textarea" :rows="4" maxlength="500" show-word-limit></el-input>
          </el-form-item>
          <el-form-item class="text-center">
            <el-button class="btn-next" @click="toNext">下一步</el-button>
          </el-form-item>
        </el-form>
      </p>
    </div>
  </div>
</template>

<script>
  import StepNavbar from "./components/StepNavbar";
  import {getSessionUser} from "../../utils/Storage";

  export default {
    name: 'Patient',
    components: {StepNavbar},
    data() {
      return {
        form: {}
      }
    },
    mounted() {
      this.$refs['stepNavbar'].setStepNode(3);
      this.$refs['stepNavbar'].setTitle("完善资料")
    },

    methods:{


      /**
       * 执行到下一步
       */
      toNext(){
        this.form.accountId = getSessionUser().id;
        this.$emit("toNext", this.form)
      },

      /**
       * 执行到上一步
       */
      toLast(){
        this.$emit("toLast", 2)
      }
    }
  }
</script>

<style scoped>
  .wrap {
    width: 1200px;
    margin: 20px auto;
  }

  .content {
    background: #FFFFFF;
    padding: 20px 90px 50px;
    margin-bottom: 50px;
  }

  .title {
    text-align: center;
    /*display: inline-block;*/
    /*transform: translateX(-12%);*/
    line-height: 50px;
    color: #ffffff;
    font-size: 13px;
  }

  .description {
    margin-bottom: 40px;
  }

  .tips {
    color: #DA575D;
    font-size: 13px;
  }

  p {
    margin-bottom: 0px;
  }

  .el-checkbox.is-bordered {
    padding-left: 0px !important;
    border: unset;
  }

  .el-button--text {
    color: #3BD1DC !important;
  }

  .el-row.step {
    height: 50px;
    margin-bottom: 10px;
  }

  .step .el-col {
    background-image: url("../../assets/images/bg-step.png");
    height: 100%;
    background-size: 100% 100%;
  }

  .step .el-col:first-child {
    background-image: url("../../assets/images/bg-step01.png");
  }

  .step .bg-white {
    background-image: url("../../assets/images/bg-step02.png");

  }

  .step .bg-white .title {
    color: #000;
  }

  .el-form {
    padding: 0px 250px;
  }

  .el-form .el-radio-group {
    width: 100%;
  }

  .el-form .btn-next {
    width: 50%;
  }

  .el-form .captcha .text-input {
    padding-left: 0px !important;
  }

  .el-form .captcha .text-captcha {
    height: 35px;
  }

  .el-form .captcha .text-captcha img {
    margin-top: 2px;
    cursor: pointer;
  }

  .el-form .el-radio-group {
    text-align: center;
  }

  .el-form .addr .el-col {
    padding: 0px 2px 0px 0px;
  }

  .el-form .addr .el-col:last-child {
    padding: 0px;
  }

  .el-row.idcard .el-col .el-image {
    width: 100%;
    min-height: 80px;
    border: 1px solid #cccccc;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }

  .el-row.idcard .el-col {
    padding: 0px 5px;
  }

  .el-row.idcard .el-col .desc {
    font-size: 12px;
    color: #cccccc;
    text-align: center;
    margin-top: -10px;
  }

  .idcard-tips {
    font-size: 12px;
    color: #F25A60;
  }
</style>
