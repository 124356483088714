<template>
  <el-row class="step-navbar">
    <el-col :span="6" class="text-center" v-for="item in bars" :key="item.node"
            :class="{'bg-white':isBgWhite(item.node)}">
      <span class="title">{{item.name}}</span>
    </el-col>
  </el-row>
</template>

<script>
  export default {
    name: 'StepNavbar',
    data() {
      return {
        bars: [
          {node: 1, name: '账号设置'},
          {node: 2, name: '身份信息登记'},
          {node: 3, name: '专业技术证件登记'},
          {node: 4, name: '收付款账号及联系方式登记'},
        ],
        thisNode: 1,
      }
    },

    methods: {

      /**
       * 是否是未操作的白色背景
       */
      isBgWhite(node) {
        return node > this.thisNode;
      },

      /**
       * 设置当前的节点信息
       */
      setStepNode(stepNode) {
        this.thisNode = stepNode;
      },

      /**
       * 设置标题
       * @param title
       */
      setTitle(title){
        this.bars.forEach(item => {
          if (item.node === this.thisNode){
            item.name = title;
          }
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .el-row.step-navbar {
    height: 50px;
    margin-bottom: 10px;

    .el-col {
      background-image: url("../../../assets/images/bg-step.png");
      height: 100%;
      background-size: 100% 100%;
    }

    .el-col:first-child {
      background-image: url("../../../assets/images/bg-step01.png");
    }

    .bg-white {
      background-image: url("../../../assets/images/bg-step02.png");

      .title {
        color: #000;
      }

    }

    .title {
      color: #ffffff;
      line-height: 48px;
    }
  }

</style>
